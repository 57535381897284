import React from 'react'
import SEO from "../components/seo"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ImgHeader from "../components/ImgHeader"
import Children from "../components/kids/Children"
import CardListing from "../components/cards/CardListing"
import Listing from "../components/ListingComponent"
import TitleDiv from "../components/elements/TitleDiv"
import CenterDiv from '../components/elements/CenterDiv';

import brain from "../assets/kids/brain.png"
import VHCenter from '../components/elements/VHCenter';
import Title from '../components/elements/Title'
import image from "../assets/kids/Kids.jpg"
import ListDiv from '../components/elements/ListDiv'

const Div = styled.div`
  border: 3px solid #ed6c2b;
  /* border-radius:5px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  height: 150px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    .mrt {
      margin-top: 5%;   
    }
  }
`;


const Kids = ({ data }) => {
  const { title } = data.ImgHeaderQuery
  const background = data.ImgHeaderQuery.backgroundImg.fluid.src

  const firstTitle = data.TitleDivQuery.edges[0].node.header
  const secondTitle = data.TitleDivQuery.edges[1].node.header
  const thirdTitle = data.TitleDivQuery.edges[2].node.header
  const fourthTitle = data.TitleDivQuery.edges[3].node.header

  const help = data.contentBlock.edges[0].node.body.childContentfulRichText.html
  const helpImage = data.contentBlock.edges[0].node.image.fluid.src
  const helpWidth = data.contentBlock.edges[0].node.width
  const helpHeight = data.contentBlock.edges[0].node.height

  const alarmingFactsText1 = data.contentBlock.edges[1].node.body.childContentfulRichText.html
  const alarmingFactsText2 = data.contentBlock.edges[2].node.body.childContentfulRichText.html

  const block1 = data.contentBlock.edges[3].node.body.childContentfulRichText.html
  const block2 = data.contentBlock.edges[4].node.body.childContentfulRichText.html
  const block3 = data.contentBlock.edges[5].node.body.childContentfulRichText.html
  const block4 = data.contentBlock.edges[6].node.body.childContentfulRichText.html

  return (
    <Layout>
      <SEO title="Kids" />
      <ImgHeader position="50% 20%" image={background} title={title} />
      <TitleDiv color="#feb600" style={{ marginTop: "0" }}>
        <VHCenter><div class="text-uppercase"><Title><div dangerouslySetInnerHTML={{ __html: firstTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div className="container mb-4">
        <div className="row center">
          <div className="col-sm-12 col-md-6 col-lg-6 center">
            <img className={helpWidth} height={helpHeight} src={helpImage} alt="brain" />
          </div>
        </div>
      </div>
      <CenterDiv className="container center font-size">
        <div dangerouslySetInnerHTML={{ __html: help }}></div>
      </CenterDiv>
      <TitleDiv color="#85b8cd">
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: secondTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <Listing path="/kids" />
      <TitleDiv color="#e9aa21">
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: thirdTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-lg-10 mb-3 avenir-roman font-size text-justify">
            <div dangerouslySetInnerHTML={{ __html: alarmingFactsText1 }}></div>
          </div>
        </div>
        <div className="container body-font-size">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3 text-center">
              <Div className="">
                <VHCenter>
                  <div className="center">
                    <div style={{ width: "90%", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: block1 }}></div>
                  </div>
                </VHCenter>
              </Div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 text-center">
              <Div className="sm-bdr-box">
                <VHCenter>
                  <div className="center">
                    <div style={{ width: "90%", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: block2 }}></div>
                  </div>
                </VHCenter>
              </Div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 text-center">
              <Div className="md-bdr-box" >
                <VHCenter>
                  <div className="center">
                    <div style={{ width: "90%", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: block3 }}></div>
                  </div>
                </VHCenter>
              </Div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 text-center">
              <Div className="md-bdr-box">
                <VHCenter>
                  <div className="center">
                    <div style={{ width: "90%", fontSize: "15px" }} dangerouslySetInnerHTML={{ __html: block4 }}></div>
                  </div>
                </VHCenter>
              </Div>
            </div>
          </div>
        </div>
        <div className="container font-size text-justify" style={{ marginTop: "3%" }} dangerouslySetInnerHTML={{ __html: alarmingFactsText2 }}></div>
        <div className="container">
          <ul className="font-size text-justify list ">
            {

              data.ListsQuery.edges.map((edge) => {
                return (
                  <li path="/kids" textLeft="left" > {edge.node.listText} </li>
                )
              })


            }
          </ul>
        </div>

      </div>
      <TitleDiv color="#ed6c2b">
        <VHCenter><div><Title><div dangerouslySetInnerHTML={{ __html: fourthTitle }}></div></Title></div></VHCenter>
      </TitleDiv>
      <div className="container" style={{ marginTop: "5%", marginBottom: "1%" }}>
        <CardListing path="/kids" btn={false} />
      </div>

    </Layout>
  )
}

export default Kids

export const query = graphql`
    query KidsPageQuery {
        ImgHeaderQuery: contentfulImgHeader(path: { eq: "/kids" }) {
            title
            path
            backgroundImg {
              fluid(quality: 100) {
                src
              }
            }
        }
        ListsQuery: allContentfulLists(
          filter: { path: { eq: "/kids" }}, 
          sort: { fields: orderId }
          )
        {
          edges {
            node {
              orderId
              listText 
            }
          }
        }
        TitleDivQuery: allContentfulTitleDiv(
          filter: { path: { eq: "/kids" }}, 
          sort: { fields: orderId }
          )
        {
          edges {
            node {
              orderId
              header 
            }
          }
        }
        contentBlock: allContentfulBlocks(
          filter: { path: { eq: "/kids" } }
          sort: { fields: orderId }
        ) {
          edges {
            node {
              height
              width
              body {
                childContentfulRichText {
                  html
                }
              }
              image{
                  fluid{
                      src
                  }
              }
            }
          }
        }
    }
`